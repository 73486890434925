
import { Component, Mixins, Prop } from "vue-property-decorator";
import CartMixin from "@/modules/cart/mixins/Cart";
import { ProductData } from "@planetadeleste/vue-mc-shopaholic";
import BSpinner from "@/components/bs/BSpinner.vue";
import { isString } from "lodash";
import EBtn from "@/components/common/EBtn.vue";
import IconCartAdd from "@/components/icons/IconCartAdd.vue";
import IconCartOk from "@/components/icons/IconCartOk.vue";

type IconStroke = 0 | 1 | 2 | 3 | "0" | "1" | "2" | "3";

@Component({
  components: { IconCartAdd, EBtn, IconCartOk, BSpinner },
})
export default class AddToCart extends Mixins(CartMixin) {
  @Prop({ type: Number, default: 0 }) readonly offerId!: number;
  @Prop({ type: Number, default: 0 }) readonly shippingTypeId!: number;
  @Prop({ type: Number, default: 0 }) readonly paymentMethodId!: number;
  @Prop({ type: Number, default: 1 }) readonly quantity!: number;
  @Prop({ type: Boolean, default: true }) readonly icon!: boolean;
  @Prop({ type: [String, Number], default: 1 })
  readonly iconStroke!: IconStroke;
  @Prop(Boolean) readonly iconOnly!: boolean;
  @Prop(Object) readonly product!: ProductData;
  @Prop({ type: String, default: "add.to.cart" }) readonly label!: string;
  @Prop({ type: [String, Number], default: "7" }) readonly variant!: string;
  @Prop([String, Object]) readonly redirectTo!: string | Record<string, any>;

  get disabled(): boolean {
    return !this.offerId;
  }

  get isAdded(): boolean {
    return !this.disabled && this.inCart(this.offerId);
  }

  get iconClass() {
    return {
      "mr-10": !this.iconOnly && !!this.label,
      [`tw-stroke-${this.iconStroke}`]: true,
      "x-flip": true,
    };
  }

  get sIconComponent(): string {
    return `icon-cart-${this.isAdded ? "ok" : "add"}`;
  }

  async onAdd(): Promise<void> {
    if (!this.offerId || this.disabled) {
      return;
    }

    if (this.isAdded && this.$route.name !== "checkout") {
      this.$router.push({ name: "checkout" });
      return;
    }

    await this.addToCart(
      this.offerId,
      this.quantity,
      undefined,
      undefined,
      this.product
    );

    if (this.redirectTo) {
      const obLocation = isString(this.redirectTo)
        ? { name: this.redirectTo }
        : this.redirectTo;
      this.$router.push(obLocation);
    }
  }
}
